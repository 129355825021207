<template>
  <div
    :class="[
      'v-table',
      'v-table--vue',
      `v-table--${id}`,
      overflow && 'v-table--overflow',
      fontSize === 'small' && 'v-table--font-size-small'
    ]"
  >
    <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
    <component :is="'style'" v-html="style"></component>
    <div class="v-table__table" ref="table">
      <table>
        <thead>
          <tr class="v-row">
            <th
              :class="[
                'v-th',
                !isMobileColumn(col) && 'v-th--hide',
                isSortColumn(col) && 'sortable'
              ]"
              v-for="(col, index) in columns"
              :key="index"
              v-on:click="columnSort(col, $event)"
            >
              <v-text font-size="2" class="text-gray-dark py-1 px-3">
                {{ col.text || col }}
                <v-loading v-if="columnLoading === col" small />
                <v-arrow
                  v-else-if="isSortColumn(col)"
                  :direction="sortDirection(col)"
                />
              </v-text>
            </th>
          </tr>
        </thead>
        <tbody>
          <slot></slot>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: Math.random()
        .toString(36)
        .substr(2, 9),
      currentSorting: this.sorting
        ? this.sorting
        : {
            sortBy: this.sortBy,
            sortDesc: this.sortDesc
          },
      overflow: false,
      resizeObserver: null,
      mutationObserver: null,
      columnLoading: null
    };
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    columnsMobile: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: ""
    },
    sortDesc: {
      type: Boolean,
      default: true
    },
    sortColumns: {
      type: Array,
      default: () => []
    },
    sortColumnIds: {
      type: Array,
      default: () => []
    },
    sorting: {
      type: Object,
      default: () => {}
    },
    fontSize: {
      type: String,
      default: null
    }
  },
  computed: {
    style() {
      if (!this.columns || !this.columns.length) {
        return;
      }
      let rules = [];
      this.columns.forEach((column, index) => {
        if (this.isMobileColumn(column)) {
          return;
        }
        rules.push(
          `.v-table--${this.id} tbody tr td:nth-child(${index +
            1}) { display: none; };`
        );
      });
      return `@media (max-width: 767.98px) { ${rules.join("")} }`;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.detectOverflow();
      this.setupObservers();
    });
    this.$root.$on("datatable:sorting-done", this.columnLoadingDone);
  },
  beforeDestroy() {
    this.$root.$off("datatable:sorting-done", this.columnLoadingDone);
    this.cleanupObservers();
  },
  methods: {
    detectOverflow() {
      const table = this.$refs.table;
      if (table) {
        this.overflow = table.scrollWidth > table.offsetWidth;
      }
    },
    setupObservers() {
      this.setupResizeObserver();
      this.setupMutationObserver();
    },
    setupResizeObserver() {
      if ("ResizeObserver" in window) {
        this.resizeObserver = new ResizeObserver(() => {
          this.$nextTick(this.detectOverflow);
        });
        this.resizeObserver.observe(this.$refs.table);
      } else {
        window.addEventListener("resize", this.detectOverflow);
      }
    },
    setupMutationObserver() {
      this.mutationObserver = new MutationObserver(() => {
        this.$nextTick(this.detectOverflow);
      });
      this.mutationObserver.observe(this.$refs.table, {
        childList: true,
        subtree: true,
        characterData: true
      });
    },
    cleanupObservers() {
      if (this.resizeObserver) {
        this.resizeObserver.disconnect();
      } else {
        window.removeEventListener("resize", this.detectOverflow);
      }
      if (this.mutationObserver) {
        this.mutationObserver.disconnect();
      }
    },
    isSortColumn(col) {
      return (
        this.sortColumns.includes(col) || this.sortColumnIds.includes(col.id)
      );
    },
    sortDirection(col) {
      let current = this.currentSorting;
      if (current.sortBy !== col && current.sortBy !== col.id) return "down";
      return current.sortDesc ? "down" : "up";
    },
    columnSort(col, event) {
      if (!this.isSortColumn(col)) {
        return;
      }
      let current = this.currentSorting;
      current.sortBy = col.id || col;
      current.sortDesc = !current.sortDesc;
      this.columnLoading = col;
      this.$emit("update:currentSorting", current);
      this.$emit("update-current", current);
    },
    columnLoadingDone() {
      this.columnLoading = null;
    },
    isMobileColumn(col) {
      if (this.columnsMobile.length === 0) {
        return true;
      }
      return (
        this.columnsMobile.includes(col.id) ||
        this.columnsMobile.includes(col.text || col)
      );
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-table {
  position: relative;

  scrollbar-color: theme-color("placeholder") theme-color("light");
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px theme-color("light");
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: theme-color("placeholder");
    outline: 1px solid theme-color("placeholder");
  }

  &__table {
    display: flex;
    overflow-x: auto;

    .v-table--overflow &:after {
      content: "";
      padding-right: 30px;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  thead {
    text-align: left;
  }

  .v-th {
    .v-text {
      display: flex;
      align-items: center;
    }

    &.sortable {
      cursor: pointer;
    }

    &--hide {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .v-arrow {
    margin-left: 10px;
  }

  .v-loading {
    margin-left: 8px;
  }

  &--font-size-small {
    ::v-deep .v-cell__text {
      @include font-size(nth($fontSizes, 2));
    }
  }
}
</style>

<style lang="scss">
@import "../../sass/component.scss";

.v-table {
  &:not(.v-table--vue) {
    position: relative;
    overflow-x: auto;

    table {
      width: 100%;
      border-collapse: collapse;

      thead {
        text-align: left;

        th {
          font-size: 0.875rem;
          font-weight: normal;
        }
      }

      tbody tr {
        &:nth-child(even) {
          background-color: transparent;
        }
        &:nth-child(odd) {
          background-color: theme-color("light");
        }
      }

      tr {
        border: 0;
      }

      td,
      th {
        border: 0;

        &:nth-of-type(1) {
          border-radius: 5px 0 0 5px;
        }

        &:last-of-type {
          border-radius: 0 5px 5px 0;
        }
      }

      th {
        color: theme-color("gray-dark");
      }

      td {
        padding: $spacer;
      }
    }
  }

  &--overflow {
    > div {
      display: flex;
      overflow-x: auto;
      cursor: grab;

      &:after {
        content: "";
        padding-right: 30px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 60px;
      top: 0;
      right: 0;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
}

@import "./v-table-overrides";
</style>
